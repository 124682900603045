<template>
  <b-container fluid class="mt-5">
    <b-row>
      <b-col class="text-center">
        <b-img src="@/assets/dark.svg"></b-img>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xs="12" sm="8" md="6" lg="5" class="max-obd-width">
        <keep-alive>
          <component
            @confirm-step="confirmStep"
            :is="currentComponent"
            :response="response"
            :loading="loading"
            :personType="personType"
            v-bind="{
              currentStep:gestor.getCurrentStep(),
              maxSteps:gestor.maxStep()
            }"
          ></component>
        </keep-alive>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <span class="text-muted copyright">Powered by</span>
        <b-img src="@/assets/bithan.svg"></b-img> <template v-if="gestor"></template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import "@/assets/scss/theme.scss"
import "@/assets/scss/main.scss"
import GestorDietrich from "@/components/onboarding/gestores/gestorDietrich.js"
import constants from "@/constants"
import _ from "lodash"

export default {
  name: "Main",
  components: {
    onboardingEnd: () => import("@/components/onboarding/FinishOnboarding"),
    SignUpForm: () => import("@/components/onboarding/SignUp"),
    UserDataForm: () => import("@/components/onboarding/UploadUserData.vue"),
    PersonTypeForm: () =>
      import("@/components/onboarding/SelectPersonType.vue"),
    UploadCompanyData: () =>
      import("@/components/onboarding/UploadCompanyData"),
    UploadDocuments: () => import("@/components/onboarding/UploadDocuments"),
    TerminosCondiciones: () =>
      import("@/components/onboarding/TerminosCondiciones")
  },
  created() {
    this.gestor = new GestorDietrich()
    this.gestor.getComponents();
  },
  data() {
    return {
      gestor: null,
      response: "",
      loading: "",
      personType: "F",
      step: 1,
      1: {},
      2: {},
      3: {},
      4: {},
      5: {}
    }
  },
  methods: {
    async confirmStep(value) {
      if (!value) return
      const steps = this.gestor.getComponentSteps();

      this[this.gestor.getCurrentStep()] = value.data
      if (this.gestor.getCurrentStep() === steps.PersonTypeForm.id) {
        this.personType = this[steps.PersonTypeForm.id].personType
        this.gestor.selectPerson(this.personType);
      }

      this.gestor.nextStep();
      
      if (!this.gestor.isLastStep()) return

      this.loading = true
      try {
        const parametros = Object.keys(steps).reduce((prev, curr, index) => {
          const source = _.cloneDeep(this[steps[curr].id])
          // prev = Object.assign(prev, source);
          prev[curr] = source;
          return prev
        }, {})
        parametros.clientOri = constants.ORIGIN_DIETRICH

        // console.log(parametros);

        const response = await this.gestor.signUpService(parametros)
        this.response = response
      } catch (error) {
        console.log(error);
        throw new Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    currentComponent() {
      return this.gestor.getCurrentComponent();
    }
  }
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}

.copyright {
  font-size: 0.8rem;
}
.max-obd-width {
  max-width: 610px;
}
</style>
