import GestorObd from "./gestorObd"
import userService from "@/services/userService"
import onboardingService from "@/services/onboardingService"
import constants from "@/constants"
import _ from "lodash"

class GestorDietrich extends GestorObd {
  async signUpService(parametros) {
    const {
      clientOri,
      SignUpForm,
      UserDataForm,
      UploadCompanyData,
      PersonTypeForm,
      UploadDocuments,
      TerminosCondiciones
    } = parametros

    let availableParameters = {
      clientOri
    }

    if (SignUpForm) {
      availableParameters = {
        ...availableParameters,
        email: SignUpForm.email,
        password1: SignUpForm.password1,
        password2: SignUpForm.password2
      }
    }

    if (UserDataForm) {
      availableParameters = {
        ...availableParameters,
        actividad: UserDataForm.actividad,
        dni: UserDataForm.dni,
        fullName: UserDataForm.fullName,
        phoneNumber: UserDataForm.phoneNumber
      }
    }

    if (UploadCompanyData) {
      availableParameters = {
        ...availableParameters,
        actividad: UploadCompanyData.actividad,
        dni: UploadCompanyData.dni,
        fullName: UploadCompanyData.fullName,
        phoneNumber: UploadCompanyData.phoneNumber
      }
    }

    if (PersonTypeForm) {
      availableParameters = {
        ...availableParameters,
        personType: PersonTypeForm.personType
      }
    }

    if (UploadDocuments) {
      availableParameters = {
        ...availableParameters,
        documents: UploadDocuments.documents,
        client_doc_data: UploadDocuments.client_doc_data
      }
    }

    if (TerminosCondiciones) {
      availableParameters = {
        ...availableParameters,
        no_politic: TerminosCondiciones.no_politic
      }
    }

    return await userService.signUp(
      // ...availableParameters,
      availableParameters.dni,
      availableParameters.email,
      availableParameters.fullName,
      availableParameters.password1,
      availableParameters.password2,
      availableParameters.phoneNumber,
      availableParameters.clientOri,
      availableParameters.actividad,
      availableParameters.personType,
      availableParameters.documents,
      availableParameters.client_doc_data,
      availableParameters.no_politic
    )
  }

  getComponents() {
    onboardingService
      .getComponentsByOrigin(constants.ORIGIN_DIETRICH)
      .then(response => {
        this.components = response.data.origins
          .map(item => {
            const newItem = {
              visible: item.visible,
              pantalla: item.component
            }
            return newItem
          })
          .filter(p => p.visible)
      })
  }

  selectPerson(personType) {
    const oldComponents = _.cloneDeep(this.components)
    // console.log("persona ha seleccionar", personType, oldComponents);
    if (personType === "F") {
      // delete oldComponents.UploadCompanyData
      this.components = oldComponents.filter(
        item => item.pantalla !== "UploadCompanyData"
      )
    } else if (personType === "J") {
      delete oldComponents.UserDataForm
      this.components = oldComponents.filter(
        item => item.pantalla !== "UserDataForm"
      )
    }
    console.log("persona seleccionada", this.components)
    // console.log("deep seleccionada", oldComponents);
  }
}

export default GestorDietrich
